import React, { useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { gsap } from "gsap"
import Img from "gatsby-image"
import { ScrollScene } from "scrollscene"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"

const getAboutContent = graphql`
  {
    aboutPageContent: allWordpressPost(
      filter: { slug: { eq: "about-page-content" } }
    ) {
      edges {
        node {
          acf {
            banner {
              header
              content
            }
            gallery {
              image_1 {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
              image_2 {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    heroBgImg: allWordpressWpHero {
      edges {
        node {
          acf {
            about_hero {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    bgImg: file(relativePath: { eq: "o6.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const AboutPage = () => {
  const { aboutPageContent, heroBgImg, bgImg } = useStaticQuery(getAboutContent)
  const aboutHeroImg =
    heroBgImg.edges[0].node.acf.about_hero.localFile.childImageSharp.fluid
  const bgImage = bgImg.childImageSharp.fluid
  const bannerHeader = aboutPageContent.edges[0].node.acf.banner.header
  const bannerContent = aboutPageContent.edges[0].node.acf.banner.content
  const gallery1 =
    aboutPageContent.edges[0].node.acf.gallery.image_1.localFile.childImageSharp
      .fluid
  const gallery2 =
    aboutPageContent.edges[0].node.acf.gallery.image_2.localFile.childImageSharp
      .fluid

  let headerContainer = useRef(null)
  let headerLogo = useRef(null)
  let headerMenu = useRef(null)

  useEffect(() => {
    //GSAP Animations
    const headerAnimation = gsap.timeline()

    headerAnimation
      .set(headerContainer.current, {
        visibility: "visible",
      })
      .from([headerLogo.current, headerMenu.current], {
        duration: 0.5,
        y: 10,
        autoAlpha: 0,
        ease: "power3.inOut",
        stagger: 0.2,
        delay: 2,
      })
  }, [])

  useEffect(() => {
    //GSAP About Content Animations
    const bannerAnimation = gsap.timeline({ paused: true })
    const imgRevealAnimation = gsap.timeline({ paused: true })

    bannerAnimation.from([".about-intro__heading", ".about-intro__content"], {
      autoAlpha: 0,
      y: 30,
      ease: "power3.easeOut",
      stagger: {
        amount: 0.5,
      },
    })

    const bannerScene = new ScrollScene({
      triggerElement: ".about-intro",
      gsap: {
        timeline: bannerAnimation,
      },
      scene: {
        reverse: false,
        triggerHook: 0.75,
      },
    })

    let revealRow = Array.prototype.slice.call(
      document.querySelectorAll(".about-gallery__img")
    )

    imgRevealAnimation.from(revealRow, {
      autoAlpha: 0,
      y: 100,
      ease: "power3.easeOut",
      stagger: {
        amount: 0.6,
      },
    })

    const imgScene = new ScrollScene({
      triggerElement: ".about-gallery",
      gsap: {
        timeline: imgRevealAnimation,
      },
      scene: {
        reverse: false,
        triggerHook: 0.75,
      },
    })
  }, [])

  return (
    <Layout
      ref={{
        headerLogo: headerLogo,
        headerMenu: headerMenu,
        headerContainer: headerContainer,
      }}
    >
      <SEO title="About" />
      <Hero
        heroTitle={`About Me`}
        heroSubtitle={`Grateful!`}
        heroBgImg={aboutHeroImg}
        bgImg={bgImage}
      />
      <section className="section-about-intro fixed-width-container">
        <div className="about-intro">
          <h1 className="heading-primary about-intro__heading">
            {bannerHeader}
          </h1>
          <p
            className="about-intro__content"
            dangerouslySetInnerHTML={{ __html: bannerContent }}
          />
        </div>
      </section>
      <section className="section-about-gallery fixed-width-container">
        <div className="about-gallery">
          <div className="about-gallery__row">
            <div className="about-gallery__col">
              <div className="about-gallery__img-wrap">
                <Img fluid={gallery1} className="about-gallery__img" alt="" />
                <div className="about-gallery__img--cover"></div>
              </div>
            </div>
            <div className="about-gallery__col">
              <div className="about-gallery__img-wrap">
                <Img fluid={gallery2} className="about-gallery__img" alt="" />
                <div className="about-gallery__img--cover"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
